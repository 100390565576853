import { useCallback } from 'react'

import {
  useAccount,
  useCanStartTrial,
  useOrganizationId,
  usePlan,
} from '~publish/legacy/accountContext'
import { DATES, setCookie } from '~publish/helpers/cookies'
import { PusherEvent, usePusherEvent } from '~publish/services/pusher'
import { usePostCounts } from '~publish/pages/AllChannels/PostList/usePostCounts'
import type { UsePostsCountResult } from '~publish/pages/AllChannels/PostList/usePostCounts'
import { useSplitEnabled } from '@buffer-mono/features'

type PusherEventData = {
  update: {
    draft: boolean
  }
}

/*
 * Returns true if the user has never created a post and false in other case
 */
export const userHasNeverCreatedAPost = (
  postCounts: UsePostsCountResult,
): boolean => {
  if (!postCounts?.loading && !postCounts?.error) {
    return (
      (postCounts?.counts?.queue ?? 0) === 0 &&
      (postCounts?.counts?.sent ?? 0) === 0
    )
  }
  return false
}

/**
 * This hooks determines if the Trial Prompt Modal should be displayed to the user
 * when a post is created.
 *
 * Conditions:
 *  - user has never created a post
 *  - post is not a draft
 *  - user is free and can start a trial
 */
export const useShouldShowTrialPromptModal = (): void => {
  const plan = usePlan()
  const isOneBufferOrganization = plan?.isOneBufferOrganization
  const isFree = plan?.id === 'free'
  const canStartTrial = useCanStartTrial()
  const organizationId = useOrganizationId()
  const postCounts = usePostCounts({
    organizationId,
  })

  // We are adding this condition to prevent the Trial Modal from showing if you are in the
  // variant of the experiment.
  const user = useAccount()

  // Should show the Trial Prompt depending on the Variant
  // We won't hook to the Post Creation Event unless you are in the control
  const {
    isEnabled: delayTrialPromptVariantB,
    isReady: delayTrialPromptVariantBIsReady,
  } = useSplitEnabled(
    'geid-remove-generic-trial-prompts-v2',
    {
      email: user?.account?.email,
    },
    'variant_b',
  )
  const {
    isEnabled: delayTrialPromptVariantA,
    isReady: delayTrialPromptVariantAIsReady,
  } = useSplitEnabled(
    'geid-remove-generic-trial-prompts-v2',
    {
      email: user?.account?.email,
    },
    'variant_a',
  )

  /**
   * Method to check the conditions when the event is stored and display the modal
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handlePostCreationEvent = useCallback(
    (data: PusherEventData) => {
      const isDraft = data.update?.draft || false
      const userEligiBleForTrial =
        isOneBufferOrganization && isFree && canStartTrial
      if (
        userHasNeverCreatedAPost(postCounts) &&
        !isDraft &&
        userEligiBleForTrial &&
        !delayTrialPromptVariantA &&
        delayTrialPromptVariantAIsReady &&
        !delayTrialPromptVariantB &&
        delayTrialPromptVariantBIsReady
      ) {
        /* Show the modal after 1 second to give time 
        the Composer to close */
        setTimeout(showStartTrialPrompt, 1_000)
      }
    },
    [canStartTrial, isFree, isOneBufferOrganization, postCounts],
  )

  /**
   * Subscription to POST_CREATED event: it is called also for drafts creation
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  usePusherEvent(
    PusherEvent.POST_CREATED,
    async (data: PusherEventData): Promise<void> => {
      handlePostCreationEvent(data)
    },
  )
}

/**
 * Shows the modal with the correct CTA and set the cookie to true, so
 * the modal will not shown again in one year.
 */
const showStartTrialPrompt = (): void => {
  const { MODALS, actions } = window.appshell || {}
  if (actions) {
    actions.openModal(MODALS.startTrialReactive, {
      ctaButton: 'startTrial',
      ctaView: 'noclick',
      ctaLocation: 'startTrialPostConfirmation',
      cta: 'noclick-startTrialPostConfirmation-startTrial-1',
      upgradePathName: 'startTrialPostConfirmation',
    })
    setCookie({
      key: 'startTrialPrompt',
      value: true,
      expires: DATES.inMonthsFromNow(12),
    })
  }
}
