import React, { type ReactNode, useEffect } from 'react'
import { connect, type ConnectedProps } from 'react-redux'

import { actions as orgActions } from '~publish/legacy/organizations'
import { getOrgIdFromRoute } from '~publish/legacy/app-pages'
import type { RootState } from '~publish/legacy/store'
import type { Profile } from '~publish/legacy/profile-sidebar/types'
import MissingAccessPage from '~publish/legacy/missing-access-page'
import { useAccount } from '~publish/legacy/accountContext'

interface OwnProps {
  children: ReactNode
  currentPath?: string
  profiles: Profile[]
}

interface StateProps {
  reduxReady: boolean
  hasCurrentOrg: boolean
  currentPath?: string
  profiles: Profile[]
}

interface DispatchProps {
  storeSelectedOrg: (currentOrgId: string) => void
}

/**
 * We use this component to delay rendering children until we have
 * enough data loaded into the state.
 */
const mapStateToProps = (state: RootState): StateProps => {
  const orgsLoaded = state.organizations.loaded
  const userLoaded = state.user.loaded
  const profilesLoaded = state.profileSidebar.loaded
  const hasCurrentOrg = !!state.organizations?.selected
  const currentPath = state?.router?.location?.pathname
  const profiles = state?.profileSidebar?.profileList

  return {
    reduxReady: orgsLoaded && userLoaded && profilesLoaded,
    hasCurrentOrg,
    currentPath,
    profiles,
  }
}

const mapDispatchToProps: DispatchProps = {
  storeSelectedOrg: (currentOrgId: string) =>
    orgActions.setCurrentOrganization(currentOrgId),
}
const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = OwnProps & PropsFromRedux

const LoadingGate: React.FC<Props> = ({
  reduxReady = false,
  children,
  storeSelectedOrg,
  hasCurrentOrg,
  profiles,
  currentPath,
}: Props) => {
  const user = useAccount()
  const selectedOrgInAccount = user?.account?.currentOrganization?.id
  const canAccessPublishing =
    // @ts-expect-error TS(2339) FIXME: Property 'canAccessPublishing' does not exist on t... Remove this comment to see the full error message
    user?.account?.currentOrganization?.billing?.canAccessPublishing
  const orgIdFromRoute = getOrgIdFromRoute({ currentPath, profiles })
  // we are using hasCurrentOrg in this check to make sure this can only be true on the initial rendering
  const needsToSelectNewOrgInOrgSwitcher =
    selectedOrgInAccount !== orgIdFromRoute &&
    !!orgIdFromRoute &&
    !hasCurrentOrg

  // Update the organization in Redux on user organization change
  useEffect(() => {
    if (reduxReady && selectedOrgInAccount && hasCurrentOrg) {
      storeSelectedOrg(selectedOrgInAccount)
    }
  }, [selectedOrgInAccount, hasCurrentOrg, reduxReady, storeSelectedOrg])

  useEffect(() => {
    // select the organization in Redux once we have fetched the user
    // this is only used in the first render
    if (
      reduxReady &&
      // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Initial... Remove this comment to see the full error message
      !user?.loading &&
      selectedOrgInAccount &&
      !hasCurrentOrg
    ) {
      // If org coming from route doesn't match the last org stored, select and store the new value
      // this only applies for the initial render otherwise the account is driving
      // This only applies if route has a valid org (not a null, undefined or empty value).
      if (needsToSelectNewOrgInOrgSwitcher) {
        const { actions } = window?.appshell || {}
        actions.setCurrentOrganization(orgIdFromRoute)
      } else {
        storeSelectedOrg(selectedOrgInAccount)
      }
    }
  }, [
    selectedOrgInAccount,
    hasCurrentOrg,
    reduxReady,
    storeSelectedOrg,
    // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Initial... Remove this comment to see the full error message
    user?.loading,
    needsToSelectNewOrgInOrgSwitcher,
    orgIdFromRoute,
  ])
  // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Initial... Remove this comment to see the full error message
  if (!user.loading && canAccessPublishing === false) {
    return <MissingAccessPage />
  }

  return children
}

export default connector(LoadingGate)
