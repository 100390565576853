import React from 'react'

import RouteMonitor from '../RouteMonitor'

interface LoaderProps {
  location?: {
    pathname?: string
  }
}

const Loader = ({ location = {} }: LoaderProps): JSX.Element => (
  <RouteMonitor pathname={location.pathname} />
)

export default Loader
