import { useEffect } from 'react'
import { HelpScoutBeacon } from '../../helpScoutBeacon'

interface RouteMonitorProps {
  pathname?: string
}

const RouteMonitor = ({ pathname = '' }: RouteMonitorProps): null => {
  useEffect(() => {
    // Let Beacon know when we've changed pages
    if (window && window.Beacon) {
      HelpScoutBeacon.updateHelpScoutBeacon(pathname)
    }
  }, [pathname])
  return null
}

export default RouteMonitor
