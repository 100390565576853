import React from 'react'

import { GlobalEmptyState } from '~publish/legacy/shared-components'
import { useAccount } from '../../../accountContext'

import { getTypeContent } from '../../utils'

type MissingAccessPageProps = {
  switchOrganization: (orgId: string) => void
  translations: {
    altText: string
    heading: string
    description: string
    primaryAction: {
      onClick: () => void
      label: string
    }
    secondaryAction: {
      onClick: () => void
      label: string
    }
  }
}

const MissingAccessPage = ({
  switchOrganization,
  translations,
}: MissingAccessPageProps): JSX.Element => {
  const user = useAccount()
  // TODO: Not sure where this organizations prop is coming from! Just in here
  // to clean up the type errors for now, so won't dig further into it.
  // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'I... Remove this comment to see the full error message
  const orgWithAccess = user?.organizations?.find(
    (org: { billing: { canAccessPublishing: boolean } }) =>
      org.billing?.canAccessPublishing,
  )

  const organization = user?.account?.currentOrganization
  const orgName = 'name' in organization ? organization?.name : null
  const isAdmin = organization?.role === 'admin'
  const content = getTypeContent({
    switchOrganization,
    translations,
    isAdmin,
    orgWithAccess,
    orgName,
  })

  return (
    <GlobalEmptyState
      imageSrc="https://buffer-publish.s3.amazonaws.com/images/illustration-publishing.png"
      altText={translations.altText}
      header={translations.heading}
      description={content.description}
      primaryAction={'primaryAction' in content ? content.primaryAction : null}
      secondaryAction={content.secondaryAction}
    />
  )
}

export default MissingAccessPage
