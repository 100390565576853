import React, { useState } from 'react'

import Banner from '@bufferapp/ui/Banner'

const dashboardBanner = 'temporary-dashboard-banner'

const getContainerStyle = (hidden: boolean): React.CSSProperties => ({
  display: hidden ? 'none' : 'flex',
})

const TopBanner = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'status' implicitly has an 'any' t... Remove this comment to see the full error message
  status,
  // @ts-expect-error TS(7031) FIXME: Binding element 'content' implicitly has an 'any' ... Remove this comment to see the full error message
  content,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCloseBanner' implicitly has an ... Remove this comment to see the full error message
  onCloseBanner,
  themeColor = 'orange',
  // @ts-expect-error TS(7031) FIXME: Binding element 'actionButton' implicitly has an '... Remove this comment to see the full error message
  actionButton,
}) => (
  <div style={getContainerStyle(status)}>
    <Banner
      // @ts-expect-error TS(2322) FIXME: Type '{ themeColor: string; onCloseBanner: any; ac... Remove this comment to see the full error message
      themeColor={themeColor}
      onCloseBanner={onCloseBanner}
      actionButton={actionButton}
      customHTML={actionButton ? null : { __html: content }}
      text={actionButton ? content : null}
    />
  </div>
)

interface TemporaryDashboardBannerProps {
  enabledApplicationModes?: Record<string, string>[]
  displayRemindersBanner?: boolean
  usernamesRemindersList?: string
}

const TemporaryDashboardBanner = ({
  enabledApplicationModes = [],
  displayRemindersBanner,
  usernamesRemindersList,
}: TemporaryDashboardBannerProps): JSX.Element | null => {
  const [hidden, hideBanner] = useState(false)

  const onCloseBannerClick = () => {
    hideBanner(!hidden)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'tag' implicitly has an 'any' type.
  const getEnabledApplicationMode = (tag) =>
    enabledApplicationModes.filter((mode) => mode.tag === tag)[0]

  // Displays Temporary Banner With Admin Message.
  if (enabledApplicationModes && getEnabledApplicationMode(dashboardBanner)) {
    const { content } = getEnabledApplicationMode(dashboardBanner)
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ status: boolean; content: any;... Remove this comment to see the full error message
    return TopBanner({
      status: hidden,
      content,
      onCloseBanner: onCloseBannerClick,
    })
  }

  // Displays Temporary Banner With Reminders Message.
  let remindersBannerMessage = ''
  if (displayRemindersBanner && usernamesRemindersList) {
    remindersBannerMessage = `Check out your queue for Instagram accounts ${usernamesRemindersList} to set up Reminders and complete your post.`
  }

  if (displayRemindersBanner && usernamesRemindersList) {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ status: boolean; content: stri... Remove this comment to see the full error message
    return TopBanner({
      status: hidden,
      content: remindersBannerMessage,
      onCloseBanner: onCloseBannerClick,
    })
  }
  return null
}

export default TemporaryDashboardBanner
