import { useMixpanelSessionReplay } from '../../hooks/useMixpanelSessionReplay'

export function MixpanelSessionReplay(): null {
  // This component is used to initialize Mixpanel session replay for all of
  // Publish. Mixpanel allows us to sample recordings to reduce usage, but this
  // allows us to place the initialization behind a split where we can further
  // reduce the sampling by targeting the split to a % of users.
  // In order for us to use splits, we need to call useMixpanelSessionReplay
  // nested underneath the Split configuration in FeaturesWrapper but outside
  // of the Router to avoid calling it on every route change.
  useMixpanelSessionReplay()

  return null
}
