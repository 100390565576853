/* eslint-disable @typescript-eslint/explicit-function-return-type */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { env } from '../env'

const canvaScript = document.createElement('script')
canvaScript.onload = async () => {
  if (!window.Canva || !window.Canva.DesignButton) {
    console.warn('Canva SDK not loaded')
    return
  }

  const api = await window.Canva.DesignButton.initialize({
    apiKey: env.VITE_CANVA_KEY,
  })

  window.canvaApi = api
}
canvaScript.src = 'https://sdk.canva.com/designbutton/v2/api.js'
document.body.prepend(canvaScript)
